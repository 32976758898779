import { toast } from "react-toastify";
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf/dist/polyfills.es.js";
import "react-toastify/dist/ReactToastify.css";
import "jspdf-barcode";
// toast.configure()

const options = {
  autoClose: 2000,
  className: "",
  position: "right",
};

export const toastSuccess = (message) => {
  console.log("Hello0 success toast");
  toast.success(message, options);
};

export const toastError = (message) => {
  toast.error(message, options);
};

export const toastWarning = (message) => {
  toast.warn(message, options);
};

export const toastInformation = (message) => {
  toast.info(message, options);
};

export const toastDark = (message) => {
  toast.dark(message, options);
};

export const toastDefault = (message) => {
  toast(message, options);
};

export const formatIndian = (str) => {
  if (str?.toString()) return str.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  else return str;
};
export const convertTo24Hour = (time) => {
  let hours, minutes, modifier;
  if (time.includes("AM") || time.includes("PM")) {
    const timeParts = time.match(/^(\d+):(\d+)([AP]M)$/);
    if (!timeParts) {
      return "Invalid time format" + time;
    }
    hours = parseInt(timeParts[1]);
    minutes = timeParts[2];
    modifier = timeParts[3];
  } else {
    // If the modifier is not provided, assume it's AM
    hours = parseInt(time.substring(0, 2));
    minutes = time.substring(3, 5);
    modifier = "AM";
  }

  if (hours === 12 && modifier === "AM") {
    hours = "00";
  } else if (modifier === "PM") {
    hours = (hours === 12 ? 12 : hours + 12).toString().padStart(2, "0");
  } else {
    hours = hours.toString().padStart(2, "0");
  }

  return `${hours}:${minutes}`;
};

export function convertToAMPM(time24h) {
  let [hours, minutes] = time24h.split(":");
  hours = parseInt(hours, 10);
  const modifier = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours}:${minutes}${modifier}`;
}

export function decodeMinutesToTime(minutes) {
  const hours = Math.floor(minutes / 60) % 24; // Calculate hours (and handle overflow for the next day)
  const minutesRemaining = minutes % 60; // Calculate remaining minutes

  // Format hours to ensure leading zero if necessary
  const formattedHours = (hours < 10 ? "0" : "") + hours;

  // Format minutes to ensure leading zero if necessary
  const formattedMinutes = (minutesRemaining < 10 ? "0" : "") + minutesRemaining;

  return `${formattedHours}:${formattedMinutes}`;
}

export function encodeTimeToMinutes(timeString) {
  // Split the time string into hours and minutes
  const [hoursStr, minutesStr] = timeString.split(":");

  // Parse hours and minutes as integers
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Calculate total minutes
  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
}

// Dummy data

export function printInvoice(data) {
  const doc = new jsPDF();
  const PS = {
    fontSize: 12,
    logoSize: 24,
    margin: 10,
  };
  let verticalOffset = 10; // Define verticalOffset variable

  doc.setFontSize(PS.fontSize + 3);
  doc.text(100, verticalOffset, "SELLER ADDRESS:");
  doc.text(155, verticalOffset, "BILLING ADDRESS:");
  doc.setFontSize(PS.fontSize - 2);
  const sellPhone = data.invoice.seller.phoneNo;
  const sell = data.invoice.seller.sellerInformation;
  const sellerAddress = `${sell.name}, ${sell.fullAddress}, Phone No:${sellPhone}`;
  const sellerLines = doc.splitTextToSize(sellerAddress.toUpperCase(), 50);
  verticalOffset += 5; // Increase vertical offset
  doc.text(100, verticalOffset, sellerLines);
  const bill = data.invoice.orderAddress;
  const billingAddress = `${bill.name}, ${bill.line1}, ${bill.line2} , ${bill.fullAddress},\nphone no. ${bill.mobileNo}`;
  const billing = doc.splitTextToSize(billingAddress.toUpperCase(), 50);
  doc.text(155, verticalOffset, billing);

  verticalOffset += 30; // Increase vertical offset

  doc.setLineWidth(0.5);
  doc.line(80, verticalOffset, 205, verticalOffset);
  verticalOffset += 2; // Increase vertical offset
  doc.barcode(`${data.invoice.id}`, {
    fontSize: 40,
    textColor: "#000000",
    x: 110,
    y: verticalOffset + 20,
  })
  doc.setFont("Helvetica", "normal");
  doc.setFontSize(15);
  doc.text(120, verticalOffset + 27, `${data.invoice.id}`);
  //canvg(canvas, svgString);
  //const barcodeDataUrl = canvas.toDataURL("image/png");
  //doc.addImage(barcodeDataUrl, "PNG", barcodeX, barcodeY, barcodeValue.length * 10 * barcodeScale, 30 * barcodeScale);

  doc.text(155, verticalOffset + 5, "ORDER DETAILS");
  doc.setFontSize(PS.fontSize - 2);
  let invoiceId = data.invoice.invoiceId ? data.invoice.invoiceId : "";
  const invoiceNumber = `Order Number: ` + data.invoice.id;
  doc.text(155, verticalOffset + 10, invoiceNumber);
  const invoiceDate = `Order Date: ${moment(data.invoice.date).format("MMM DD, YYYY")}`;
  doc.text(155, verticalOffset + 15, invoiceDate);
  const deliveryDate = `Delivery Date: ${moment(data.invoice.deliveryDate.date ? data.invoice.deliveryDate.date : data.invoice.deliveryDate).format(
    "MMM DD, YYYY"
  )}`;
  doc.text(155, verticalOffset + 20, deliveryDate);
  const deliverySlot = `Delivery Slot: ${data.invoice.deliveryTime.slot}`;
  doc.text(155, verticalOffset + 25, deliverySlot);
  let paymentMode = data.invoice.paymentMode ? data.invoice.paymentMode : "prepaid";
  doc.text(155, verticalOffset + 30, `Payment Mode: ${paymentMode.toUpperCase()}`);
  verticalOffset += 15; // Increase vertical offset
  verticalOffset += 5; // Increase vertical offset
  verticalOffset += 5; // Increase vertical offset
  verticalOffset += 5; // Increase vertical offset
  verticalOffset += 6; // Increase vertical offset
  doc.setLineWidth(0.5);
  doc.line(PS.margin, verticalOffset, 200, verticalOffset + 1);
  let width1Percentage = 1.5;
  const table = {
    snoSize: 10,
    descSize: 20,
    unitSize: 45,
    hsnSize: 20,
    skuSize: 10,
    particularSize: 24,
    gstSize: 12,
    rateSize: 10,
    qunatitySize: 7,
  };
  const snoX = 10,
    hsnX = snoX + width1Percentage * table.snoSize,
    descripX = hsnX + width1Percentage * table.descSize,
    unitX = descripX + width1Percentage * table.unitSize,
    unitPriceX = unitX + width1Percentage * table.hsnSize,
    qtyX = unitPriceX + width1Percentage * table.gstSize,
    totalX = qtyX + width1Percentage * table.qunatitySize;
  verticalOffset += 5; // Increase vertical offset
  doc.text(snoX, verticalOffset, "S.No");
  doc.text(descripX, verticalOffset, "Product Name");
  doc.text(hsnX, verticalOffset, "Item Code");
  doc.text(unitX, verticalOffset, "Unit");
  doc.text(unitPriceX, verticalOffset, "Sell Price");
  doc.text(qtyX, verticalOffset, "Qty");
  doc.text(totalX, verticalOffset, "Total Amt.");
  verticalOffset += 2; // Increase vertical offset
  doc.line(PS.margin, verticalOffset, 200, verticalOffset);
  let products = data.invoice.products;
  let Tqty = 0;
  let Tvalue = 0;
  verticalOffset += 4; // Increase vertical offset
  verticalOffset += 1; // Increase vertical offset
  doc.setLineWidth(0.1);

  for (let i = 0; i < products.length; i++) {
    doc.text(snoX, verticalOffset, i + 1 + ".");
    var hsnText = products[i].hsnCode ? Math.round(products[i].hsnCode).toString() : ''; // Check if hsnCode exists
    doc.text(hsnX, verticalOffset, hsnText);

    // unit
    var unitText = products[i].recommendedAttribute ? products[i].recommendedAttribute.toString() : ''; // Check if recommendedAttribute exists
    doc.text(unitX, verticalOffset, unitText);

    // unitPrice
    var unitPriceText = products[i].sellPrice ? products[i].sellPrice.toString() : ''; // Check if sellPrice exists
    doc.text(unitPriceX, verticalOffset, unitPriceText);

    // quantity
    var qtyText = products[i].quantity ? products[i].quantity.toString() : ''; // Check if quantity exists
    doc.text(qtyX, verticalOffset, qtyText);

    // total
    var totalText = (products[i].sellPrice && products[i].quantity) ? (products[i].sellPrice * products[i].quantity).toFixed(2).toString() : ''; // Check if sellPrice and quantity exist
    doc.text(totalX, verticalOffset, totalText);

    const lines = products[i].name.match(/.{1,30}/g);
    for (let j = 0; j < lines.length; j++) {
      if (verticalOffset > 280) {
        doc.addPage();
        verticalOffset = 10;
      }
      doc.text(descripX, verticalOffset, lines[j].toUpperCase());
      verticalOffset += 5; // Increase vertical offset
    }

    Tqty += products[i].quantity;
    Tvalue += products[i].sellPrice * products[i].quantity;

    doc.line(PS.margin, verticalOffset, 200, verticalOffset);
    verticalOffset += 5; // Increase vertical offset
  }

  // doc.line(PS.margin, verticalOffset, 200, verticalOffset);
  verticalOffset += 5; // Increase vertical offset
  const discount = data.invoice.discount;
  let discountValue = 0;
  let discountRatio = discountValue / Tvalue;
  doc.setFontSize(PS.fontSize + 1);
  verticalOffset += 5; // Increase vertical offset
  doc.setLineWidth(0.5);

  if (verticalOffset > 280) {
    doc.addPage();
    verticalOffset = 10;
  }
  doc.line(PS.margin, verticalOffset, 200, verticalOffset);
  verticalOffset += 5; // Increase vertical offset
  doc.setFont(undefined, "bold");
  if (verticalOffset > 280) {
    doc.addPage();
    verticalOffset = 10;
  }
  doc.text(10, verticalOffset, "Total Qty :");
  doc.text(35, verticalOffset, Tqty.toString());
  doc.text(70, verticalOffset, "Delivery Charge :");
  doc.text(110, verticalOffset, Math.round(data.invoice.deliveryCharge).toString());
  doc.setFontSize(PS.fontSize + 1);
  doc.setFont(undefined, "bold");
  doc.text(135, verticalOffset, "Total Amount :");
  doc.text(170, verticalOffset, Math.round(data.invoice.amount).toString());
  doc.setFont(undefined, "normal");
  verticalOffset += 2; // Increase vertical offset
  doc.line(PS.margin, verticalOffset, 200, verticalOffset);
  width1Percentage = 2;
  const taxTable = {
    gstNoSize: 15,
    taxableAmtSize: 20,
    tax: 12,
    cgstSize: 12,
    sgstSize: 12,
    cessSize: 12,
    totalAmtSize: 15,
  };
  const gstNoX = 10,
    taxableAmtX = gstNoX + width1Percentage * taxTable.gstNoSize,
    taxX = taxableAmtX + width1Percentage * taxTable.taxableAmtSize,
    cgstX = taxX + width1Percentage * taxTable.tax,
    sgstX = cgstX + width1Percentage * taxTable.cgstSize,
    cessX = sgstX + width1Percentage * taxTable.sgstSize,
    totalAmtX = cessX + width1Percentage * taxTable.cessSize;
  verticalOffset += 5; // Increase vertical offset
  // doc.setFontType("normal");
  verticalOffset += 2; // Increase vertical offset
  // doc.line(PS.margin, verticalOffset, 200, verticalOffset);
  // doc.setFontType("normal");
  doc.setFontSize(PS.fontSize);



  doc.setFontSize(13);
  doc.text(PS.margin, verticalOffset, "");
  verticalOffset += 6; // Increase vertical offset
  doc.text(PS.margin, verticalOffset + 30, "");

  if (verticalOffset > 280) {
    doc.addPage();
    verticalOffset = 10;
  }
  // ADDRESS 3 TImes
  for (let i = 10; i <= 150; i += 70) {
    const bill1 = data.invoice.orderAddress;
    const billingAddress1 = `${bill1.name} \n${bill1.line1}, ${bill1.line2}, ${bill1.fullAddress}\nPhone: ${bill1.mobileNo}`;
    const billing1 = doc.splitTextToSize(billingAddress1.toUpperCase(), 50);
    doc.text(i, verticalOffset, billing1);
  }
  doc.save(`${data.invoice.id}.pdf`);
}

function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}
