import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { baseURL, liveURL } from "../../config/config";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import axios from "axios";

export const SingleProductNewPageTwo = () => {
  const location = useLocation();
  const productDetail = location.pathname.split("/")[2];
  const categoryId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const [product, setProduct] = useState<any>();
  const [subCategories, setSubCategories] = useState<any>([]);
  const [leafCategories, setLeafCategories] = useState<any>();
  const [tree, setTree] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [subBrands, setSubBrands] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [imageArray, setImageArray] = useState<any>([]);
  const [previewLinks, setPreviewLinks] = useState<any>([]);
  const bulkUploadRef = React.useRef<any>(null);

  const resetStateValues = () => {
    setSubCategories([]);
    setLeafCategories(undefined);
    setTree([]);
    setBrands([]);
    setSubBrands([]);
    setLoading(false);
    setImageArray([]);
    setPreviewLinks([]);
  };

  const renderDetails = () => {
    setProduct(null);
    setLoading(true);
    setProduct({
      barCode: "",
      name: "",
      description: "",
      recommendedAttribute: "",
      mrp: null,
      purchasePrice: null,
      minSellPrice: null,
      price: null,
      membershipPrice: null,
      hsnCode: "",
      gst: null,
      urlKey: "",
      isOrder: false,
      isLastBuying: false,
      isSubscription: false,
      isMorningBuy: false,
      brand: {
        _id: "",
        image: [],
        tags: [],
        isRootBrand: false,
        childIds: [],
        isActive: false,
        name: "",
        _name: "",
        lName: "",
        description: "",
        lDescription: "",
        updated: null,
        created: null,
        date: null,
        id: null,
        __v: null,
        total: null,
      },
      subBrand: {
        _id: "",
        image: null,
        tags: [],
        isRootBrand: false,
        childIds: [],
        isActive: false,
        name: "",
        _name: "",
        lName: "",
        parentId: "",
        description: "",
        lDescription: "",
        updated: null,
        created: null,
        date: null,
        id: null,
        __v: null,
      },
      rootCatId: "",
      subCatId: "",
      leafCatId: "",
      images: [],
      tags: [],
      seo: {
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
      },
      shipping: {
        dimensions: {
          height: null,
          length: null,
          width: null,
        },
        weight: null,
      },
      competitor: {
        grofers: {
          productId: null,
          lastPrice: null,
          merchantId: null,
        },
        milkbasket: {
          productId: null,
          lastPrice: null,
        },
        bigbasket: {
          productId: null,
          lastPrice: null,
        },
        grocio: {
          productId: null,
          lastPrice: null,
        },
      },
      parentId: "",
      isParent: false,
    })
    getDataSec(`${baseURL}/category/tree/v2`)
      .then((res2) => {
        if (res2.success) {
          getDataSec(`${baseURL}/brands?start=&end=`)
            .then((res3) => {
              if (res3 && res3.success) {
                setBrands(res3.brand);

              }
            })
            .catch((err) => {
              toast.error("Error in fetching categories");
            });
          setTree(res2.category);
        }
      })
      .catch((err) => {
        toast.error("Error in fetching categories");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    renderDetails();
  }, []);

  useEffect(() => {
    getDataSec(`${baseURL}/brands?start=&end=`)
      .then((res) => {
        if (res && res.success) {
          setBrands(res.brand);
        }
      })
      .catch((err) => {
        toast.error("Error in fetching categories");
      });
  }, []);

  useEffect(() => {
    if (product?.brand?._id) {
      getDataSec(`${baseURL}/brand?brandId=${product?.brand._id}`)
        .then((res) => {
          if (res && res.success) {
            setSubBrands(res.brand.subBrands);
            setProduct((prev) => ({ ...prev, subBrand: null }));
          }
        })
        .catch((err) => {
          toast.error("Error in fetching categories");
        });
    }
  }, [product?.brand?._id]);

  const validations = () => {
    if (!product?.name) {
      toast.error("Please enter product name");
      return false;
    }
    if (!product?.description) {
      toast.error("Please enter product description");
      return false;
    }
    if (!product?.rootCategory?._id) {
      toast.error("Please select root category");
      return false;
    }
    if (!product?.subCategory?._id) {
      toast.error("Please select sub category");
      return false;
    }
    if (!product?.leafCategory?._id) {
      toast.error("Please select leaf category");
      return false;
    }
    if (!product?.brand?.id) {
      toast.error("Please select brand");
      return false;
    }
    if (!product?.subBrand?.id) {
      toast.error("Please select sub brand");
      return false;
    }
    if (!product?.recommendedAttribute) {
      toast.error("Please enter attribute");
      return false;
    }
    if (!product?.shipping?.weight || product?.shipping?.weight === 0) {
      toast.error("Please enter weight");
      return false;
    }
    if (!product?.hsnCode) {
      toast.error("Please enter HSN code");
      return false;
    }
    if (!product?.barCode) {
      toast.error("Please enter barcode");
      return false;
    }
    if (!product?.price) {
      toast.error("Please enter MRP");
      return false;
    }
    if (!product?.sellPrice) {
      console.log(product);
      toast.error("Please enter sell price");
      return false;
    }
    if (!product?.minSellPrice) {
      toast.error("Please enter min sell price");
      return false;
    }
    if (!product?.seo?.metaTitle) {
      toast.error("Please enter meta title");
      return false;
    }
    if (!product?.seo?.metaKeywords) {
      toast.error("Please enter meta keywords");
      return false;
    }
    if (!product?.seo?.metaDescription) {
      toast.error("Please enter meta description");
      return false;
    }
    if (!product?.description) {
      toast.error("Please enter product description");
      return false;
    }
    return true;
  };

  const handleUpdate = () => {
    if (!validations()) return;
    postDataSec(`${baseURL}/product/add`, {
      barCode: product?.barCode,
      name: product?.name,
      description: product?.description,
      recommendedAttribute: product?.recommendedAttribute,
      mrp: product?.price,
      price: product?.sellPrice,
      purchasePrice: 0,
      minSellPrice: product?.minSellPrice,
      membershipPrice: 0,
      hsnCode: product?.hsnCode,
      gst: 0,
      urlKey: product?.name
        .replace(/[^\w\s]|_/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase(),
      isOrder: true,
      isLastBuying: false,
      isSubscription: false,
      isMorningBuy: false,
      brand: { name: product?.brand?.name, _id: product?.brand?.id, id: product?.brand?.id },
      subBrand: {
        name: product?.subBrand?.name,
        _id: product?.subBrand?.id,
        id: product?.subBrand?.id,
      },
      rootCatId: product?.rootCategory._id,
      subCatId: product?.subCategory._id,
      leafCatId: product?.leafCategory._id,
      images: [],
      tags: product?.tags,
      seo: {
        metaTitle: product?.seo?.metaTitle,
        metaDescription: product?.seo?.metaDescription,
        metaKeywords: product?.seo?.metaKeywords,
      },
      shipping: {
        dimensions: {
          height: 0,
          length: 0,
          width: 0,
        },
        weight: product?.shipping?.weight,
      },
      competitor: {
        grofers: {
          productId: null,
          lastPrice: null,
          merchantId: null,
        },
        milkbasket: {
          productId: null,
          lastPrice: null,
        },
        bigbasket: {
          productId: null,
          lastPrice: null,
        },
        grocio: {
          productId: null,
          lastPrice: null,
        },
      },
      parentId: "",
      isParent: true,

    })
      .then((res) => {
        if (res.success) {
          toast.success("Product updated successfully.");
          resetStateValues();
          renderDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ padding: 10, backgroundColor: "white", display: "flex", gap: 10 }}>
      <div
        className="editModal"
        style={{
          width: "90%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          maxWidth: "90%",
        }}
      >
        <div>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                flex: 1,
                flexDirection: "row",
                gap: 20,
                marginTop: "20px",
              }}
            >
              <h3>Loading Details</h3>
              <CircularProgress size={18} sx={{ color: "#aaa" }} />{" "}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                flexDirection: "row",
                gap: 20,
                marginTop: "20px",
              }}
            >
              <h3> Add Product</h3>
              <button style={{ height: 30, width: 80 }} onClick={handleUpdate}>
                Create
              </button>
              <button style={{ height: 30, width: 80 }} onClick={() => navigate("/products")}>
                Cancel
              </button>
            </div>
          )}
        </div>
        {product && (
          <table border={1} style={{ overflow: "hidden" }}>
            <tbody>
              <tr style={{ minHeight: 50 }}>
                <td colSpan={1}>Name</td>
                <td colSpan={4}>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.name}
                    onChange={(e) => setProduct((prev) => ({ ...prev, name: e.target.value }))}
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td colSpan={1}>Description</td>
                <td colSpan={4}>
                  <textarea
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    value={product?.description}
                    onChange={(e) =>
                      setProduct((prev) => ({ ...prev, description: e.target.value }))
                    }
                  ></textarea>
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Category</td>
                <td>
                  <select
                    style={{
                      border: "none",
                      textTransform: "capitalize",
                      width: "100%",
                      color: "#0a3b64",
                      fontWeight: "bold",
                    }}
                    value={product?.rootCategory?._id}
                    onChange={(e) => {
                      setProduct((prev) => ({
                        ...prev,
                        rootCategory: { ...prev.rootCategory, _id: e.target.value },
                      }));
                      tree.forEach((element) => {
                        if (element._id === e.target.value) {
                          setSubCategories(element.children);
                          setLeafCategories([]);
                        }
                      });
                    }}
                  >
                    <option value="">--- Root Category ---</option>
                    {tree?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {product?.rootCategory?._id && (
                <tr style={{ minHeight: 50 }}>
                  <td>Sub Category </td>
                  <td>
                    <select
                      style={{
                        border: "none",
                        width: "100%",
                        textTransform: "capitalize",
                        color: "#0a3b64",
                        fontWeight: "bold",
                      }}
                      value={product?.subCategory?._id}
                      onChange={(e) => {
                        setProduct((prev) => ({
                          ...prev,
                          subCategory: { ...prev.subCategory, _id: e.target.value },
                        }));
                        subCategories.forEach((element) => {
                          if (element._id === e.target.value) {
                            setLeafCategories(element.children);
                          }
                        });
                      }}
                    >
                      <option value="">Sub Category</option>
                      {subCategories?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )}
              {product?.subCategory?._id && (
                <tr style={{ minHeight: 50 }}>
                  <td>Leaf Category</td>
                  <td>
                    <select
                      style={{
                        border: "none",
                        width: "100%",
                        textTransform: "capitalize",
                        color: "#0a3b64",
                        fontWeight: "bold",
                      }}
                      value={product?.leafCategory?._id}
                      onChange={(e) => {
                        setProduct((prev) => ({
                          ...prev,
                          leafCategory: { ...prev.leafCategory, _id: e.target.value },
                        }));
                      }}
                    >
                      <option value="">Leaf Category</option>
                      {leafCategories?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )}

              <tr style={{ minHeight: 50 }}>
                <td>Brand</td>
                <td>
                  <select
                    style={{
                      border: "none",
                      textTransform: "capitalize",
                      width: "100%",
                      color: "#0a3b64",
                      fontWeight: "bold",
                    }}
                    value={product?.brand?.id}
                    onChange={(e) => {
                      setProduct((prev) => ({
                        ...prev,
                        subBrand: { id: "selectBrand", value: "selectBrand" },
                        brand: {
                          _id: e.target.value,
                          id: e.target.value,
                          name: brands?.find((item) => item._id === e.target.value)?.name,
                        },
                      }));
                    }}
                  >
                    <option value="Brand"> Brand</option>
                    {brands?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Sub Brand {subBrands.length} </td>
                <td>
                  <select
                    style={{
                      border: "none",
                      textTransform: "capitalize",
                      width: "100%",
                      color: "#0a3b64",
                      fontWeight: "bold",
                    }}
                    value={product?.subBrand?.id}
                    onChange={(e) => {
                      setProduct((prev) => ({
                        ...prev,
                        subBrand: {
                          _id: e.target.value,
                          id: e.target.value,
                          name: subBrands?.find((item) => item._id === e.target.value)?.name,
                        },
                      }));
                    }}
                  >
                    <option value="Sub Brand">Sub Brand</option>
                    {subBrands?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr style={{ minHeight: 50 }}>
                <td>Attribute</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.recommendedAttribute}
                    onChange={(e) =>
                      setProduct((prev) => ({ ...prev, recommendedAttribute: e.target.value }))
                    }
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Weight</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.shipping?.weight}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        setProduct((prev) => ({ ...prev, shipping: { weight: e.target.value } }));
                      }
                    }}
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>HSN Code</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.hsnCode}
                    onChange={(e) => setProduct((prev) => ({ ...prev, hsnCode: e.target.value }))}
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Barcode</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.barCode}
                    onChange={(e) => setProduct((prev) => ({ ...prev, barCode: e.target.value }))}
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>MRP </td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.price}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        setProduct((prev) => ({ ...prev, price: e.target.value }));
                      }
                    }}
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Sell Price</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.sellPrice}
                    onChange={(e) => setProduct((prev) => ({ ...prev, sellPrice: e.target.value }))}
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Min Sell Price</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.minSellPrice}
                    onChange={(e) =>
                      setProduct((prev) => ({ ...prev, minSellPrice: e.target.value }))
                    }
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Meta Title</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.seo?.metaTitle}
                    onChange={(e) =>
                      setProduct((prev) => ({
                        ...prev,
                        seo: { ...prev.seo, metaTitle: e.target.value },
                      }))
                    }
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Meta Keywords</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.seo?.metaKeywords}
                    onChange={(e) =>
                      setProduct((prev) => ({
                        ...prev,
                        seo: { ...prev.seo, metaKeywords: e.target.value },
                      }))
                    }
                  />
                </td>
              </tr>

              <tr style={{ minHeight: 50 }}>
                <td>Meta Description</td>
                <td>
                  <input
                    style={{ border: "none", width: "100%", color: "#0a3b64", fontWeight: "bold" }}
                    type="text"
                    value={product?.seo?.metaDescription}
                    onChange={(e) =>
                      setProduct((prev) => ({
                        ...prev,
                        seo: { ...prev.seo, metaDescription: e.target.value },
                      }))
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const handleSave = (product) => {
  if (!product?.name) {
    toast.error("Please enter product name");
    return;
  }
  if (!product?.description) {
    toast.error("Please enter product description");
    return;
  }
  if (!product?.rootCategory?._id) {
    toast.error("Please select root category");
    return;
  }
  if (!product?.subCategory?._id) {
    toast.error("Please select sub category");
    return;
  }
  if (!product?.leafCategory?._id) {
    toast.error("Please select leaf category");
    return;
  }
  if (!product?.brand?.id) {
    toast.error("Please select brand");
    return;
  }
  if (!product?.subBrand?.id) {
    toast.error("Please select sub brand");
    return;
  }
  if (!product?.recommendedAttribute) {
    toast.error("Please enter attribute");
    return;
  }
  if (!product?.shipping?.weight || product?.shipping?.weight === 0) {
    toast.error("Please enter weight");
    return;
  }
  if (!product?.hsnCode) {
    toast.error("Please enter HSN code");
    return;
  }
  if (!product?.barCode) {
    toast.error("Please enter barcode");
    return;
  }
  if (!product?.price) {
    toast.error("Please enter MRP");
    return;
  }
  if (!product?.sellPrice) {
    toast.error("Please enter sell price");
    return;
  }
  if (!product?.minSellPrice) {
    toast.error("Please enter min sell price");
    return;
  }
  if (!product?.seo?.metaTitle) {
    toast.error("Please enter meta title");
    return;
  }
  if (!product?.seo?.metaKeywords) {
    toast.error("Please enter meta keywords");
    return;
  }
  if (!product?.seo?.metaDescription) {
    toast.error("Please enter meta description");
    return;
  }
  if (!product?.description) {
    toast.error("Please enter product description");
    return;
  }
  postDataSec(baseURL + "/product/add", {
    barCode: product?.barCode,
    name: product?.name,
    description: product?.description,
    recommendedAttribute: product?.recommendedAttribute,
    mrp: product?.mrp,
    purchasePrice: 0,
    minSellPrice: product?.minSellPrice,
    price: product?.price,
    membershipPrice: 0,
    hsnCode: product?.hsnCode,
    gst: 0,
    urlKey: product?.name
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase(),
    isOrder: true,
    isLastBuying: false,
    isSubscription: false,
    isMorningBuy: false,
    brand: product?.brand,
    subBrand: product?.subBrand,
    rootCatId: product?.rootCategory._id,
    subCatId: product?.subCategory._id,
    leafCatId: product?.leafCategory._id,
    images: [],
    tags: product?.tags,
    seo: {
      metaTitle: product?.metaTitle,
      metaDescription: product?.metaDescription,
      metaKeywords: product?.metaKeywords,
    },
    shipping: {
      dimensions: {
        height: 0,
        length: 0,
        width: 0,
      },
      weight: product?.shipping?.weight,
    },
    competitor: {
      grofers: {
        productId: null,
        lastPrice: null,
        merchantId: null,
      },
      milkbasket: {
        productId: null,
        lastPrice: null,
      },
      bigbasket: {
        productId: null,
        lastPrice: null,
      },
      grocio: {
        productId: null,
        lastPrice: null,
      },
    },
    parentId: "",
    isParent: true,
  })
    .then((res) => {
      if (res.success) {
        toast.success("Product added successfully.");
        // navigate('/products')
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
