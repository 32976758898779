import React, { useEffect, useState } from 'react';
import { getDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { Loader } from 'rsuite';

const PromoCodeOrders = ({ promocode, setSelectedPromoCode }) => {

    const [loading, setLoading] = useState(true);
    const [orderList, setOrderList] = useState([]);
    const getOrders = async () => {
        try {
            setLoading(true);
            const res = await getDataSec(`${baseURL}/offer/orders?offerId=${promocode}`);
            setOrderList(res.orders);
        } catch (err) {
            console.log("Error in getting orders", err);
        } finally {
            setLoading(false);
        }
    }

    const formatDate = (date) => {
        const temp = new Date(date);
        return temp.toLocaleString();
    }

    useEffect(() => {
        getOrders();
    }, [promocode])

    return (
        <div className="editModalContainer">
            <div className="editModal" style={{ overflowY: 'scroll', height: "80%" }}>
                <div className="editModalHeader">
                    <h2 className="editModalTitle">Orders</h2>
                    <button className="closeIcon" onClick={() => setSelectedPromoCode('')}>&times;</button>
                </div>
                <div className='editModalBody'>
                    {loading ? <Loader inverse center content="loading..." /> :
                        <table style={{
                            borderCollapse: 'separate',
                            borderSpacing: 0
                        }}>
                            <thead>
                                <tr>
                                    <th>Order Id</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Promocode Discount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderList?.map((order, index) => (
                                    <tr key={index} style={{border: '1px solid #000'}}>
                                        <td>{order.id}</td>
                                        <td>{order.amount}</td>
                                        <td>{formatDate(order.date)}</td>
                                        <td>{order.couponDiscount}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>}
                </div>
            </div>
        </div >
    );
}

export default PromoCodeOrders;
