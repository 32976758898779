import React, { useEffect, useState } from 'react';
import { getDataSec, postDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderProductList } from '../OrderDetails/OrderProductList';
import './Complaints.css'
import BackSvg from '../../assets/back.svg';
import { toast } from 'react-toastify';


const ComplaintDetail = () => {

    const location = useLocation();
    const issueId = location.pathname.split('/')[2];
    const [resolutionType, setResolutionType] = useState("")
    const [resolutionMessage, setResolutionMessage] = useState("")
    const navigate = useNavigate();

    const [issue, setIssue] = useState({});

    const getIssue = async () => {
        const result = await getDataSec(`${baseURL}/issue?issueId=${issueId}`);
        if (result.success) {
            setIssue(result.issue);
        }
    }

    useEffect(() => {
        getIssue();
    }, [])

    const handleBackBtn = () => {
        navigate(-1);
    }

    function convertTimeToIST(zuluTime) {
        return new Date(zuluTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
    }

    const handleActivateAccount = async (phoneNo) => {
        try {
            const data = {
                phoneNo: phoneNo
            }
            const result = await postDataSec(`${baseURL}/reactivateUser`, data);
            if (result.success) {
                toast.success(result.message);
            }
            else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log("error activating account : ", err);
            toast.error("error activating account");
        }
    }

    const updateIssue = async () => {
        try {
            if (!resolutionType || resolutionType === "" ) {
                toast.error("Select resolution type");
                return;
            }
            if (!resolutionMessage || resolutionMessage === '') {
                toast.error("Select resolution Message");
                return;
            }
            const result = await postDataSec(`${baseURL}/issue/close`, {
                issueId: issue._id,
                status: 'closed',
                type: resolutionType,
                message: resolutionMessage
            });
            if (result.success) {
                toast.success(result.message);
                getIssue();
            } else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log("error in updating issue : ", err);
            toast.error("Error in updating issue")
        }
    }

    return (
        <div>
            <div id="topBtnsDiv">
                <button id="backBtn" onClick={handleBackBtn}><img src={BackSvg} style={{ width: 20, height: 20 }} />Back</button>
            </div>
            <div id='issueDetailContainer' >
                <div id="sellerDetails">
                    <h2>Issue Details</h2>
                    <div className="line"></div>
                    <h4>id : <span> {issue.id}</span></h4>
                    <h4>Type : <span> {issue.type}</span></h4>
                    <h4>Description : <span> {issue.description}</span></h4>
                    {
                        issue.status !== "closed" ?
                            <div style={{ marginTop: 40, flexDirection: 'column', display: 'flex' }}>
                                <h2>Resolution Status</h2>
                                <div className="line"></div>
                                <label>Resolution Type</label>
                                <select contentEditable={issue.status !== "closed"} style={{ width: 100, margin: '10px 0' }} value={resolutionType} onChange={(e) => { setResolutionType(e.target.value) }}>
                                    <option value={""}>---Select---</option>
                                    <option value={'refunded'}>Refunded</option>
                                    <option value={'resolved'}>Resolved</option>
                                    <option value={'unableToContact'}>Unable to Contact</option>
                                </select>
                                <label>Resolution Message</label>
                                <textarea rows={4} value={resolutionMessage} onChange={(e) => { setResolutionMessage(e.target.value) }} />

                                <button
                                    onClick={updateIssue}
                                    style={{ padding: 10, marginTop: 20, backgroundColor: 'rgb(250,238,40)',fontWeight: 600,color: '#000', border: '1px solid #eee', borderRadius: 8 }}>Mark as resolved</button>
                            </div> :
                            <div style={{ marginTop: 40, flexDirection: 'column', display: 'flex' }}>
                                <h2>Resolution Status</h2>
                                <div className="line"></div>
                                <h4>Status : <span>closed</span></h4>
                                <h4>Resolution Type : <span>{issue.resolutionType}</span></h4>
                                <h4>Resolution Message : <span>{issue.resolutionMessage}</span></h4>
                            </div>
                    }
                </div>
                <div id="orderDetails">
                    <h2>Customer Details</h2>
                    <div className="line"></div>
                    <h4>Email : <span> {issue.email}</span></h4>
                    <h4>Phone Number : <span> {issue.phoneNo}</span></h4>
                    <h4>Account status : <span>{issue?.user?.isSoftDelete ? "Deleted" : "Active"}</span></h4>
                    {issue?.user?.isSoftDelete &&
                        <button onClick={() => handleActivateAccount(issue.phoneNo)} style={{ width: 80, height: 30 }}>Reactivate Account</button>}
                </div>
            </div>


            {issue && issue.order ?
                <div id='orderDetailsRootContainer' >

                    <div id="orderDetailsContainer">
                        <div id="sellerDetails">
                            <h2>Seller Details</h2>
                            <div className="line"></div>
                            <h4>Aap ka Bazar</h4>
                            <span>{issue.order?.sellerInfo?.sellerInformation?.fullAddress}</span>
                            <h4>Landmark: <span>{issue.order?.sellerInfo?.sellerInformation?.landmark}</span></h4>
                            <span>{issue.order?.sellerInfo?.phoneNo}</span>
                            {issue.order?.customerMessage ?
                                <span style={{ marginTop: 40, backgroundColor: '#ff00002e', border: '1px solid red', padding: '5px', borderRadius: 8 }}> Customer Message : {issue.order.customerMessage}</span>
                                : null}
                        </div>
                        <div id="orderDetails">
                            <h2>Order Details</h2>
                            <div className="line"></div>
                            <h4>{issue.order?.address.name}</h4>
                            <span>{issue.order?.address.line1}</span>
                            <span>{issue.order?.address.line2}</span>
                            <span>{issue.order?.address.mobileNo}</span>
                            <div className="line"></div>
                            <h4>Order ID: <span>{issue.order?.id}</span>        Payment Mode: <span>{issue.order?.paymentMode ? issue.order?.paymentMode : 'online'}</span></h4>
                            <h4>Order Status: <span>{issue.order?.status}</span></h4>
                        </div>
                    </div>

                    <div id="dateDiv">
                        <h4>Order Date: <span>{convertTimeToIST(issue.order?.date)}</span></h4>
                        <h4>Delivery Date: <span>{convertTimeToIST(issue.order?.deliveryDate)}</span></h4>
                        <h4>Delivery Slot: <span>{issue.order?.deliveryTime.slot}</span></h4>
                    </div>

                    <div id="productList">
                        <table>
                            <thead className="headerRow">
                                <tr>
                                    <th>Sr</th>
                                    <th>Item/SKU Code</th>
                                    <th>Product Name</th>
                                    <th>MRP(₹)</th>
                                    <th>Sell Price(₹)</th>
                                    <th>Total Qty</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    issue.order.product.map((item, index) => (<OrderProductList index={index} item={item} isEditable={false} products={issue.order.product} setProducts={() => { }} />))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div id="dateDiv">
                        <h4>Total Items: <span>{issue.order?.product.length}</span></h4>
                        <h4>Delivery Charge: <span>{issue.order?.deliveryCharge}</span></h4>
                        {issue.order?.offerId && issue.order?.offerId !== null ? <h4>PromoCode Discount: <span>{issue.order?.couponDiscount}</span></h4> : null}
                        <h4>Total Amount: <span>{issue.order?.amount}</span></h4>
                    </div>
                </div> :
                <></>}
        </div>
    );
}

export default ComplaintDetail;
