import React, { useEffect, useState } from 'react';
import { getDataSec, postDataSec, putDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { toast } from 'react-toastify';
import './Tags.css';

const Tags = () => {

    const [tagList, setTagList] = useState([]);
    const [selectedTag, setSelectedTag] = useState();
    const [currentTagDetails, setCurrentTagDetails] = useState(null);

    const getTags = async () => {
        try {
            const result = await getDataSec(baseURL + '/taglist');
            if (result.data) {
                setTagList(result.data);
            } else {
                toast.error("Unable to fetch tags");
                console.log(result.message);
            }
        } catch (err) {
            console.log("error in fetching tags:", err);
            toast.error("Error while fetching tags");
        }
    }

    useEffect(() => {
        getTags();
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const copyTagLink = (item) => {
        navigator.clipboard.writeText(`https://aapkabazar.co/tags/${item.id}/${item._name}`).then(() => {
            toast.success("Copied to clipboard");
        })
    }

    const modifyTag = async () => {
        try {
            if (!currentTagDetails.data?._id) {
                if (!currentTagDetails.data.name) {
                    toast.error("Tag name is required");
                    return;
                }
                const data = {
                    isActive: currentTagDetails.data.isActive,
                    tag_name: currentTagDetails.data.name,
                    products: currentTagDetails?.products?.map((item) => item.id) || []
                }
                console.log(data)
                const result = await postDataSec(baseURL + '/addTags', data);
                if (result.success) {
                    toast.success(result.message);
                    setSelectedTag(false);
                    setCurrentTagDetails(null);
                    getTags();
                } else {
                    toast.error(result.message);
                }
                return;
            }

            if (selectedTag._id && selectedTag._id === null) {
                toast.error("Tag Id not found");
            }
            const data = {
                isActive: currentTagDetails.data.isActive,
                tag_name: currentTagDetails.data.name,
                products: currentTagDetails.products.map((item) => item.id),
                tag_id: currentTagDetails.data._id
            }

            const result = await putDataSec(baseURL + '/updateTags', data);
            if (result.success) {
                toast.success(result.message);
                setCurrentTagDetails(null);
                setSelectedTag(false);
                getTags();
            } else {
                toast.error(result.message);
            }


        } catch (err) {
            console.log("error in updating tag", err);
            toast.error("Error in updating tags")
        }
    }

    return (
        <div style={{ display: 'flex', flex: 1, padding: 20, gap: 20 }}>
            <div className='tagListContainer'>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                    <h3>Tags List</h3>
                    <button
                        onClick={() => { setSelectedTag(true); setCurrentTagDetails({ data: { isActive: true, name: "" }, products: [] }) }}
                        style={{ border: '1px solid #eee', padding: '10px 20px', width: 120, marginBottom: 10, backgroundColor: '#ccc', borderRadius: 8, alignSelf: 'flex-end' }}>
                        Add New +
                    </button>
                </div>
                <table className="tagListTable">
                    <tbody>
                        {tagList.map((item, index) => (
                            <tr key={index}>
                                <td style={{ textTransform: 'capitalize', paddingRight: 10, width: 40, }}>{item.id}</td>
                                <td style={{ textTransform: 'capitalize', paddingRight: 10, width: 40, color: !item?.isActive ? "red" : "green" }}>{item.isActive ? "On" : "Off"}</td>
                                <td colSpan={2} style={{ textTransform: 'capitalize', paddingRight: 10, width: 200, }}>{item.name}</td>
                                <td>
                                    <button
                                        onClick={() => {
                                            getDataSec(`${baseURL}/getTag/${item._id}`).then((res) => {
                                                if (res && res.success) {
                                                    setSelectedTag(true);
                                                    setCurrentTagDetails(res);
                                                }
                                            });
                                        }}
                                        style={{
                                            border: '1px solid green',
                                            backgroundColor: '#00aa0022',
                                            padding: '10px 20px',
                                            width: 80,
                                            marginBottom: 10,
                                            borderRadius: 8,
                                            color: "green"
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>

                                <td>
                                    <button
                                        onClick={() => copyTagLink(item)}
                                        style={{
                                            border: '1px solid blue',
                                            backgroundColor: '#0000aa22',
                                            padding: '10px 20px',
                                            width: 80,
                                            marginBottom: 10,
                                            borderRadius: 8,
                                            color: "blue"
                                        }}
                                    >
                                        Copy
                                    </button>
                                </td>

                                <td>
                                    <button
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this tag?")) {
                                                getDataSec(baseURL + '/deleteTag/' + item._id).then((res) => {
                                                    if (res && res.success) {
                                                        toast.success(res.message);
                                                        setSelectedTag(false);
                                                        setCurrentTagDetails(null);
                                                        getTags();
                                                    } else {
                                                        toast.error(res?.message || "Something went wrong");
                                                    }
                                                }).catch((err) => {
                                                    toast.error("Client Error");
                                                });
                                            }
                                        }}
                                        style={{
                                            border: '1px solid red',
                                            backgroundColor: '#ff000022',
                                            padding: '10px 20px',
                                            width: 80,
                                            marginBottom: 10,
                                            borderRadius: 8,
                                            color: "red"
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            {
                selectedTag &&
                <div className='tagEditorContainer'>
                    <div>
                        <div style={{ display: 'flex', marginBottom: 30, flexDirection: 'row', gap: 20, }} >
                            <button onClick={() => { setSelectedTag(false); setCurrentTagDetails(null) }}>Cancel</button>
                            <button onClick={() => { modifyTag() }}>Save</button>
                            {currentTagDetails.data._id && <button onClick={() => {
                                const newId = prompt("Enter Product Id");
                                if (!newId || Number(newId) < 0) return;
                                const newProds = currentTagDetails.products;
                                newProds.push({ id: newId, name: "-", barCode: "-" });
                                setCurrentTagDetails((prev) => ({ ...prev, products: newProds }))
                            }}>Add Product</button>}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <label>Tag Name</label>
                            <input onChange={(e) => {
                                setCurrentTagDetails((prev) => ({ ...prev, data: { ...prev.data, name: e.target.value } }))
                            }} value={currentTagDetails.data.name} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <label>Status</label>
                            <input onChange={(e) => {
                                setCurrentTagDetails((prev) => ({ ...prev, data: { ...prev.data, isActive: e.target.checked } }))
                            }} type='checkbox' checked={currentTagDetails.data.isActive} />
                        </div>
                        <div style={{ maxHeight: '60vh', padding: 10, overflow: 'scroll' }} >
                            <table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Product Name</th>
                                        <th>Barcode</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTagDetails.products.map((prod, idx) => (
                                        <tr key={idx}>
                                            <td>{prod.id}</td>
                                            <td>{prod.name}</td>
                                            <td>{prod.barCode}</td>
                                            <td><button onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                const newProds = currentTagDetails.products.filter((item) => item.id !== prod.id);
                                                setCurrentTagDetails((prev) => ({ ...prev, products: newProds }))
                                            }} >Remove</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>

            }
        </div>
    );
}

export default Tags;
