import { BrowserRouter as Router } from "react-router-dom";
import MiniDrawer from "./NewComponents/HomePage/HomePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./NewComponents/Login/Login";

function App() {
  return (
    <Router>
      <div style={{ backgroundColor: "#F3F9F7", width: "100%", minHeight: "100vh" }}>
        {/* <LeftPanel /> */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          stacked />
        {localStorage.getItem('token2') === null ? (
          <Login />
        ) : (
          <>

            <MiniDrawer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
