import React, { useEffect, useState } from 'react';
import { getDataSec, postDataSec, putDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';

const emptySeller = {
    email: '',
    phoneNo: '',
    shippingAddress: {
        fullAddress: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        businessEntityName: '',
        isVerified: false
    },
    returnAddress: {
        fullAddress: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        businessEntityName: '',
        isVerified: false
    },
    invoiceAddress: {
        fullAddress: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        businessEntityName: '',
        isVerified: false
    },
    bank: {
        accountHolderName: '',
        accountNumber: '',
        ifsc: '',
        bankName: '',
        isVerified: false
    },
    taxInformation: {
        gst: '',
        pan: '',
        isVerified: false
    }
}

const Seller = () => {
    const [sellers, setSellers] = useState<any>([]);
    const [selectedSeller, setSelectedSeller] = useState<any>(null);

    const saveData = async () => {
        try {
            let data = {
                taxInformation: {
                    gst: selectedSeller.taxInformation.gst,
                    pan: selectedSeller.taxInformation.pan
                },
                bank: {
                    accountHolderName: selectedSeller.bank.accountHolderName,
                    accountNumber: selectedSeller.bank.accountNumber,
                    ifsc: selectedSeller.bank.ifsc,
                    bankName: selectedSeller.bank.bankName
                },
                invoiceAddress: {
                    fullAddress: selectedSeller.invoiceAddress.fullAddress,
                    landmark: selectedSeller.invoiceAddress.landmark,
                    city: selectedSeller.invoiceAddress.city,
                    pincode: selectedSeller.invoiceAddress.pincode,
                    state: selectedSeller.invoiceAddress.state,
                    businessEntityName: selectedSeller.invoiceAddress.businessEntityName
                },
                returnAddress: {
                    fullAddress: selectedSeller.returnAddress.fullAddress,
                    landmark: selectedSeller.returnAddress.landmark,
                    city: selectedSeller.returnAddress.city,
                    pincode: selectedSeller.returnAddress.pincode,
                    state: selectedSeller.returnAddress.state,
                    businessEntityName: selectedSeller.returnAddress.businessEntityName
                },
                shippingAddress: {
                    fullAddress: selectedSeller.shippingAddress.fullAddress,
                    landmark: selectedSeller.shippingAddress.landmark,
                    city: selectedSeller.shippingAddress.city,
                    pincode: selectedSeller.shippingAddress.pincode,
                    state: selectedSeller.shippingAddress.state,
                    businessEntityName: selectedSeller.shippingAddress.businessEntityName
                },
                phoneNo: selectedSeller.phoneNo,
                email: selectedSeller.email,
                isActivate: selectedSeller.isActivate,
                _id: selectedSeller._id
            }

            const result = await postDataSec(baseURL + '/seller/profile', data);
            if (result.success) {
                toast.success('Seller updated successfully');
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.error('Error updating seller:', error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDataSec(baseURL + '/seller/list');
                if (response.success) {
                    setSellers(response.data);
                } else {
                    setSellers([]);
                }
            } catch (error) {
                console.error('Error fetching sellers:', error);
            }
        };

        fetchData();
    }, []);

    const addNewSeller = () => {
        setSelectedSeller(emptySeller);
    }

    const activateDeactivateSeller = async (sellerId, status) => {
        try {
            const response = await putDataSec(baseURL + '/seller/activate/deactivate', { sellerId: sellerId, isActivate: status });
            if (response.success) {
                toast.success(`Seller ${status ? 'activated' : 'deactivated'}`);
                setSelectedSeller(prev => ({ ...prev, isActivate: status }));
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error updating seller:', error);
        }
    }

    return (
        <div style={{ display: "flex", flex: 1, gap: 20, padding: 20, overflow: 'hidden', maxHeight: 'calc(100vh - 104px)' }}>
            <div style={{ display: "flex", flex: 0.3, backgroundColor: 'white', borderRadius: 12, flexDirection: 'column', }}>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', padding: 20, maxHeight: 30 }}>
                    <h1 style={{ margin: 0 }}>Sellers</h1>
                    <button onClick={() => { addNewSeller() }} style={{ maxHeight: 30, height: 30, borderRadius: 4, border: '1px solid #eee', backgroundColor: 'white' }}>+ ADD NEW</button>
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 10, padding: 10, overflowY: 'scroll', overflowX: 'hidden' }}>
                    {sellers.map((seller) => (
                        <button onClick={() => {
                            setSelectedSeller(emptySeller); setTimeout(() => {
                                setSelectedSeller(seller)
                            }, 100);
                        }}
                            style={{ backgroundColor: 'white', borderRadius: 16, border: '1px solid #eee', padding: 10 }}>
                            <p style={{ margin: 0, textAlign: 'left', textTransform: 'capitalize', fontSize: 18, fontWeight: 600 }}>{seller?.email}</p>
                            <p key={seller._id} style={{ margin: 0, textAlign: 'left' }}>{seller?.sellerInformation?.name}</p>
                        </button>
                    ))}
                </div>
            </div>
            {selectedSeller && selectedSeller !== null && (
                <div style={{ gap: 10, display: 'flex', flex: 0.7, flexDirection: 'column', overflowY: 'scroll', overflowX: 'hidden' }}>
                    <div style={{ display: "flex", backgroundColor: 'white', borderRadius: 12, padding: 20, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Seller Details</h3>
                            <label className="switch">
                                <input
                                    // disabled={disableButtons}
                                    type="checkbox"
                                    checked={selectedSeller?.isActivate}
                                    onClick={() => {
                                        activateDeactivateSeller(selectedSeller._id, !selectedSeller.isActivate);
                                    }}
                                />
                                <span className="slider"></span>
                            </label>

                        </div>
                        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                            <TextField label="Phone Number" variant="standard" value={selectedSeller.phoneNo} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, phoneNo: e.target.value })) }} />
                            <TextField label="Email" variant="standard" value={selectedSeller.email} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, email: e.target.value })) }} />
                            <TextField label="Full Address" variant="standard" value={selectedSeller.shippingAddress?.fullAddress} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, fullAddress: e.target.value } })) }} />
                            <TextField label="Landmark" variant="standard" value={selectedSeller.shippingAddress?.landmark} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, landmark: e.target.value } })) }} />
                            <TextField label="City" variant="standard" value={selectedSeller.shippingAddress?.city} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, city: e.target.value } })) }} />
                            <TextField label="Pincode" variant="standard" value={selectedSeller.shippingAddress?.pincode} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, pincode: e.target.value } })) }} />
                            <TextField label="State" variant="standard" value={selectedSeller.shippingAddress?.state} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, state: e.target.value } })) }} />
                        </div>
                    </div>


                    {/* Shipping Address Details */}
                    <div style={{ display: "flex", backgroundColor: 'white', borderRadius: 12, padding: 20, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Shipping Address Details</h3>
                            {/* {
                                selectedSeller?.shippingAddress?.isVerified ?
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <img style={{ height: 24, width: 24 }} src={verifySvg} alt="user" id="profileImage" />
                                        <p>Verified</p>
                                    </div> : <>
                                        <button onClick={() => { verifyInfo('shippingAddress') }}>Verify</button>
                                    </>
                            } */}
                        </div>
                        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                            <TextField label="Buisness Entity Name" variant="standard" value={selectedSeller.shippingAddress?.businessEntityName} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, businessEntityName: e.target.value } })) }} />
                            <TextField label="Full Address" variant="standard" value={selectedSeller.shippingAddress?.fullAddress} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, fullAddress: e.target.value } })) }} />
                            <TextField label="Landmark" variant="standard" value={selectedSeller.shippingAddress?.landmark} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, landmark: e.target.value } })) }} />
                            <TextField label="City" variant="standard" value={selectedSeller.shippingAddress?.city} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, city: e.target.value } })) }} />
                            <TextField label="Pincode" variant="standard" value={selectedSeller.shippingAddress?.pincode} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, pincode: e.target.value } })) }} />
                            <TextField label="State" variant="standard" value={selectedSeller.shippingAddress?.state} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, shippingAddress: { ...prev.shippingAddress, state: e.target.value } })) }} />
                        </div>
                    </div>


                    {/* Return Address Details */}
                    <div style={{ display: "flex", backgroundColor: 'white', borderRadius: 12, padding: 20, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Return Address Details</h3>
                            {/* {
                                selectedSeller?.returnAddress?.isVerified ?
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <img style={{ height: 24, width: 24 }} src={verifySvg} alt="user" id="profileImage" />
                                        <p>Verified</p>
                                    </div> : <>
                                        <button onClick={() => { verifyInfo('returnAddress') }}>Verify</button>
                                    </>
                            } */}
                        </div>
                        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                            <TextField label="Buisness Entity Name" variant="standard" value={selectedSeller.returnAddress?.businessEntityName} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, returnAddress: { ...prev.returnAddress, businessEntityName: e.target.value } })) }} />
                            <TextField label="Full Address" variant="standard" value={selectedSeller.returnAddress?.fullAddress} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, returnAddress: { ...prev.returnAddress, fullAddress: e.target.value } })) }} />
                            <TextField label="Landmark" variant="standard" value={selectedSeller.returnAddress?.landmark} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, returnAddress: { ...prev.returnAddress, landmark: e.target.value } })) }} />
                            <TextField label="City" variant="standard" value={selectedSeller.returnAddress?.city} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, returnAddress: { ...prev.returnAddress, city: e.target.value } })) }} />
                            <TextField label="Pincode" variant="standard" value={selectedSeller.returnAddress?.pincode} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, returnAddress: { ...prev.returnAddress, pincode: e.target.value } })) }} />
                            <TextField label="State" variant="standard" value={selectedSeller.returnAddress?.state} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, returnAddress: { ...prev.returnAddress, state: e.target.value } })) }} />
                        </div>
                    </div>

                    {/* Invoice Address Details */}
                    <div style={{ display: "flex", backgroundColor: 'white', borderRadius: 12, padding: 20, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Invoice Address Details</h3>
                            {/* {
                                selectedSeller?.invoiceAddress?.isVerified ?
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <img style={{ height: 24, width: 24 }} src={verifySvg} alt="user" id="profileImage" />
                                        <p>Verified</p>
                                    </div> : <>
                                        <button onClick={() => { verifyInfo('invoiceAddress') }}>Verify</button>
                                    </>
                            } */}
                        </div>
                        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                            <TextField label="Buisness Entity Name" variant="standard" value={selectedSeller.invoiceAddress?.businessEntityName} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, invoiceAddress: { ...prev.invoiceAddress, businessEntityName: e.target.value } })) }} />
                            <TextField label="Full Address" variant="standard" value={selectedSeller.invoiceAddress?.fullAddress} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, invoiceAddress: { ...prev.invoiceAddress, fullAddress: e.target.value } })) }} />
                            <TextField label="Landmark" variant="standard" value={selectedSeller.invoiceAddress?.landmark} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, invoiceAddress: { ...prev.invoiceAddress, landmark: e.target.value } })) }} />
                            <TextField label="City" variant="standard" value={selectedSeller.invoiceAddress?.city} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, invoiceAddress: { ...prev.invoiceAddress, city: e.target.value } })) }} />
                            <TextField label="Pincode" variant="standard" value={selectedSeller.invoiceAddress?.pincode} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, invoiceAddress: { ...prev.invoiceAddress, pincode: e.target.value } })) }} />
                            <TextField label="State" variant="standard" value={selectedSeller.invoiceAddress?.state} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, invoiceAddress: { ...prev.invoiceAddress, state: e.target.value } })) }} />
                        </div>
                    </div>


                    {/* Bank Details */}
                    <div style={{ display: "flex", backgroundColor: 'white', borderRadius: 12, padding: 20, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Bank Details</h3>
                            {/* {
                                selectedSeller?.bank?.isVerified ?
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <img style={{ height: 24, width: 24 }} src={verifySvg} alt="user" id="profileImage" />
                                        <p>Verified</p>
                                    </div> : <>
                                        <button onClick={() => { verifyInfo('bank') }}>Verify</button>
                                    </>
                            } */}
                        </div>
                        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                            <TextField label="Account Holder Name" variant="standard" value={selectedSeller.bank?.accountHolderName} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, bankDetails: { ...prev.bankDetails, accountHolderName: e.target.value } })) }} />
                            <TextField label="Account Number" variant="standard" value={selectedSeller.bank?.accountNumber} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, bankDetails: { ...prev.bankDetails, accountNumber: e.target.value } })) }} />
                            <TextField label="IFSC Code" variant="standard" value={selectedSeller.bank?.ifsc} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, bankDetails: { ...prev.bankDetails, ifsc: e.target.value } })) }} />
                            <TextField label="Bank Name" variant="standard" value={selectedSeller.bank?.bankName} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, bankDetails: { ...prev.bankDetails, bankName: e.target.value } })) }} />
                        </div>
                    </div>


                    {/* Tax information */}
                    <div style={{ display: "flex", backgroundColor: 'white', borderRadius: 12, padding: 20, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Bank Details</h3>
                            {/* {
                                selectedSeller?.taxInformation?.isVerified ?
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <img style={{ height: 24, width: 24 }} src={verifySvg} alt="user" id="profileImage" />
                                        <p>Verified</p>
                                    </div> : <>
                                        <button onClick={() => { verifyInfo('taxInformation') }}>Verify</button>
                                    </>
                            } */}
                        </div>
                        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                            <TextField label="GST" variant="standard" value={selectedSeller.taxInformation?.gst} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, taxInformation: { ...prev.taxInformation, gst: e.target.value } })) }} />
                            <TextField label="PAN" variant="standard" value={selectedSeller.taxInformation?.pan} onChange={(e) => { setSelectedSeller((prev) => ({ ...prev, taxInformation: { ...prev.taxInformation, pan: e.target.value } })) }} />
                        </div>
                        <button
                            onClick={() => {
                                saveData();
                            }}
                            style={{ height: 30, width: 60, backgroundColor: 'white', border: '1px solid #eee', marginTop: 30, alignSelf: 'flex-end' }}>Save</button>
                    </div>

                </div>)}
        </div>
    );
};

export default Seller;