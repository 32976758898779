import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const SingleProductPage = ({ selectedProductId, setSelectedProductId, tree, brands }) => {
    const [product, setProduct] = useState<any>({});
    const [subCategories, setSubCategories] = useState<any>([]);
    const [leafCategories, setLeafCategories] = useState<any>();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const result = await getDataSec(baseURL + `/product?productId=${selectedProductId}&categoryId=61eaecaf2da73066de6b35ee`);
                if (result.success) {
                    console.log(result.product);
                    setProduct(result.product);
                }
                else {
                    toast.error(result.message);
                }
                tree.forEach(element => {
                    if (element._id === result.product.rootCategory._id) {
                        setSubCategories(element.children);
                        element.children.forEach(element => {
                            if (element._id === result.product.subCategory._id) {
                                setLeafCategories(element.children);
                            }
                        });
                    }
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchProduct();

        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [selectedProductId])

    if (!product || !product._id) return null;
    return (
        <div className="editModalContainer">
            <div className="editModal" style={{ width: '90%', maxWidth: '90%' }}>
                <div className="editModalHeader">
                    <h3>Edit Product</h3>
                    <button className="closeIcon" onClick={() => setSelectedProductId(null)}>x</button>
                </div>
                <div className="editModalBody">
                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" value={product.name}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, description: e.target.value }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" value={product.name}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, description: e.target.value }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" value={product.name}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, description: e.target.value }))
                                }} />
                        </div>
                    </div>

                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Description</label>
                            <textarea value={product.description}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, description: e.target.value }))
                                }} />
                        </div>
                    </div>

                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Category</label>
                            <select value={product.rootCategory._id}
                                onChange={(e) => {
                                    setProduct((prev) => ({
                                        ...prev,
                                        rootCategory: {
                                            ...prev.rootCategory,
                                            _id: e.target.value
                                        }
                                    }));
                                    tree.forEach(element => {
                                        if (element._id === e.target.value) {
                                            console.log(element);
                                            setSubCategories(element.children);
                                        }
                                    });
                                }}>
                                <option value="">--- Root Category ---</option>
                                {tree?.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Sub Category</label>
                            <select value={product.subCategory._id}
                                onChange={(e) => {
                                    setProduct((prev) => ({
                                        ...prev,
                                        subCategory: {
                                            ...prev.subCategory,
                                            _id: e.target.value
                                        }
                                    }));
                                    subCategories.forEach(element => {
                                        if (element._id === e.target.value) {
                                            setLeafCategories(element.children)
                                        }
                                    });
                                }}>
                                <option value="">--- Sub Category ---</option>
                                {subCategories?.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Leaf Category</label>
                            <select value={product.leafCategory._id}
                                onChange={(e) => {
                                    setProduct((prev) => ({
                                        ...prev,
                                        leafCategory: {
                                            ...prev.leafCategory,
                                            _id: e.target.value
                                        }
                                    }));
                                }}>
                                <option value="">--- Leaf Category ---</option>
                                {leafCategories?.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Brand</label>
                            <input type="text" value={product.brand.id}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, recommendedAttribute: e.target.value }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>Sub Brand</label>
                            <input type="text" value={product.subBrand.id}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, recommendedAttribute: e.target.value }))
                                }} />
                        </div>
                    </div>

                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Attribute</label>
                            <input type="text" value={product.recommendedAttribute}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, recommendedAttribute: e.target.value }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>GST</label>
                            <input type="text" value={product.gst}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, gst: e.target.value }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>HSNCode</label>
                            <input type="text" value={product.hsnCode}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, hsnCode: e.target.value }))
                                }} />
                        </div>
                    </div>

                    <div className="form-group-container">
                        <div className="form-group">
                            <label>URL key</label>
                            <input type="text" value={product.urlKey}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, urlKey: e.target.value }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>Meta Title</label>
                            <input type="text" value={product.seo?.metaTitle}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, seo: { ...prev.seo, metaTitle: e.target.value } }))
                                }} />
                        </div>
                        <div className="form-group">
                            <label>Meta keywords</label>
                            <input type="text" value={product.seo?.metaKeywords}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, seo: { ...prev.seo, metaKeywords: e.target.value } }))
                                }} />
                        </div>
                    </div>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Meta Description</label>
                            <textarea value={product.seo?.metaDescription}
                                onChange={(e) => {
                                    setProduct((prev) => ({ ...prev, seo: { ...prev.seo, metaDescription: e.target.value } }))
                                }} />
                        </div>
                    </div>


                    {/* <Editor
                            editorState={product.description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setProductDesc}
                        />; */}
                </div>
                <div className="editModalFooter">
                    <button className="btn btn-primary">Save</button>
                    <button className="btn btn-secondary" onClick={() => setSelectedProductId(null)}>Cancel</button>
                </div>
            </div>
        </div >
    )
}